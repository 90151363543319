import React, { Component } from 'react';
import TextCard from './TextCard/TextCard';


class BodyCard extends Component {
    render (){
    
    const title1 = '¿Las flores son frescas?'
    const title2 = '¿La entrega es inmediata?'
    const title3 = '¿Incluye delivery?'
    const title4 = '¿Que medios de pago tienes?'
    const title5 = '¿Haces modelos personalizados?'
    const title6 = '¿Incluye devoluciones?'
    const title7 = '¿Puedo recoger mi pedido en tu taller?'
    const title8 = '¿Tienes modelos hechos?'
    const title9 = '¿Se puede separar con anticipacion?'

    const texto1 = 'Las flores son compradas durante los dias Martes y Jueves que son los dias que trae el proveedor.';
    const texto2 = 'Los pedidos se hacen con 48horas de anticipacion.';
    const texto3 = 'Incluye para las zonas cercanas a nuestro taller, tales como Santa Clara, Huaycan, Pariachi, Chaclacayo. No incluye zonas fuera de las nombradas.';
    const texto4 = 'Si tenemos diferentes medios de pago como transferencia, efectivo, y otros.';
    const texto5 = 'Si podemos hacerlo. Nos traes tu modelo o nos comentas tus ideas y te podemos brindar un opcion.';
    const texto6 = 'Si, en caso llegue el arreglo en mal estado. Durante las 24 horas se realizara la devolucion.';
    const texto7 = 'Claro que si. Agendamos una cita en el taller para poder.';
    const texto8 = 'Si, lo puedes revisar en nuestros modelos.';
    const texto9 = 'Si, lo puedes hacer comunicandote a cualquiera de nuestros puntos de contacto.';


    return (
        <div className='img-back-body1 pv6' >
            <div className='tc'>
                <p className='fw6 f3 f2-ns lh-title pt2 ma3'>Dudas y Respuestas</p>
                <a href="#acuerdo" class="f5 no-underline bg-animate  hover-pink:pink inline-flex items-center grow">
                <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.32488 2L17 16.9119L29.6751 2H4.32488Z" fill="black" stroke="black" stroke-width="4"/>
                </svg>
                </a>
            </div>

            <div id='acuerdo' className='pv4 cf flex-ns justify-center-ns' >
                <div className='fl w-100 w-25-ns ph2'>
                    <TextCard contenido = {texto1} title = {title1}/>
                    <TextCard contenido = {texto2} title = {title2}/>
                    <TextCard contenido = {texto3} title = {title3}/>
                </div>
                <div className='fl w-100 w-25-ns ph2'>
                    <TextCard contenido = {texto4} title = {title4}/>
                    <TextCard contenido = {texto5} title = {title5}/>
                    <TextCard contenido = {texto6} title = {title6}/>
                </div>
                <div className='fl w-100 w-25-ns ph2'>
                    <TextCard contenido = {texto7} title = {title7}/>
                    <TextCard contenido = {texto8} title = {title8}/>
                    <TextCard contenido = {texto9} title = {title9}/>
                </div>

                </div>
        </div>
    );
    
    }
    }
    
    export default BodyCard;
    