import React from 'react';

const  TextCard = (props) => {
return (
    <article className=" br3 ba db b--black-10 grow mv3">
        <h1 className="f4 bg-near-white br3 br--top black mv0 pv2 ph3 tc">{props.title}</h1>
        <div className="pa3 bt b--black-10">
            <p className="f6 f5-ns lh-copy measure">{props.contenido}</p>
        </div>
    </article>
    );

}


export default TextCard;






