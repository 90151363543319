import React from "react";
import { slide as Menu } from "react-burger-menu";
import './Sidebar.css';
import logo from './logosolo2.png'

const Sidebar = ({ onRouteChange }) => {



  return (
    // Pass on our props


    <Menu

    width={ '50%' }
    right
    className= 'flex flex-column align-items justify-center'
    >
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <div>
{/*         <p
          className="pointer tc  f3 f2-m f1-l ma2 hover-orange"
          onClick = {() => window.open('http://localhost:3000/yamheru/#nosotros', '_blank')} 
          // onClick={ () => onRouteChange('nosotros') }
          >
          Nosotros
        </p> */}
        <p
          className="pointer tc f3 f2-m f1-l ma2 hover-orange"
          onClick = {() => window.open('https://yamheru.wispform.com/32f79abf?flores=xxxx', '_blank')} 
          >
          Flores
        </p>
        <p
          className="pointer tc f3 f2-m f1-l ma2 hover-orange"
          onClick = {() => window.open('https://yamheru.wispform.com/97b9a515?suscripcion=xxxx', '_blank')} 
          >
          Suscripcion
        </p>
        <p
          className="pointer tc f3 f2-m f1-l ma2 hover-orange"
          onClick = {() => window.open('https://yamheru.wispform.com/67a2f5bc?arte=xxxx', '_blank')} 
          >
          Arte
        </p>

      </div>


      <div className="o-50">

        <div className="flex flex-wrap  items-center justify-center  mt4">

          <div className="flex flex-column w-50-l center">

              <div className="flex flex-column  items-center justify-center mb4-l cf tc center mt4">
              <h1 className="f3 f2-m f1-l ma0  lh-solid">Contácto</h1>
              <article className="fl w-100 dib-ns w-auto-ns  mt4 tc">
                <a className=" link hover-silver near-white dib mv1 mh2 tc" href="https://instagram.com/yamheru" target="_blank" rel='noreferrer'>
                  <i className="fab fa-instagram f2"></i>
                </a>
                <a className="link hover-silver near-white dib mv1 mh2 tc" href="https://facebook.com/yamheru" target="_blank" rel='noreferrer'>
                    <i className="fab fa-facebook-square f2"></i>
                </a>
                <a className="link hover-silver near-white dib mv1 mh2 tc" href="https://wa.me/51948018337?text=Hola%2C+Yamheru%2C+Estoy+interesado+en+sus+diseños+florales" target="_blank" rel='noreferrer'>
                    <i className="fab fa-whatsapp f2"></i>
                </a>


        
              </article>

              <article className="fl w-100 dib-ns w-auto-ns mt3  tc">
                <a href="tel:+51-902551938" className="f6 db fw1 pv2 link dim white" title="Call Lima office.">
                  +51-948018337
                </a>
              </article>

              <article className="fl w-100 dib-ns w-auto-ns  tc">
                <a href="mailto:hola@yamheru.com" className="f6 db fw1 pv2 link dim white" title="Mail office.">hola@yamheru.com</a>
              </article>

            </div>
          </div>

        </div>
      </div>


      <div className="o-50 tc dt dt--fixed w-100 flex flex-column" >
        <img 
          className='w-10-ns w-40' 
          src={logo}
          alt='Yamheru Logo'
          />
        <div className="">
          <p className="f7 black-70 dib mb3 white">
          Copyright©Yamheru 2021
          </p>
        </div>

      </div>


    </Menu>
  );
};


export default Sidebar;
