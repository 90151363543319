import React from 'react';
//import imgBlog from './jardin3.jpg'

const  Card = (props) => {
return (
    <article className={props.estilo}>
        <div className='tc'>
            <h3 className='fw1 f1 f-5-ns lh-title mv0'>{props.number}</h3>
            <hr className='w-20' />
            <h1 className='fw1 f3 f2-ns lh-title mv0'>{props.text}</h1>
            
        </div>
        <p className='tc lh-copy measure center fw1 f3-ns f6 ph3'>{props.content}</p>
        <div className=''>
            <img
                src={props.imgBlog}
                className=' db br4 br--bottom mw-100 h-auto'
                alt='Yamheru Art and Floral Design'

                />
        </div>
    </article>
    );

}


export default Card;