import React, { Component } from 'react';
import Button from '../Button/Button';
import './CallAction.css';


class CallAction extends Component{
    constructor(props){
        super(props);
        // this.sayHello = this.sayHello.bind(this);
        this.state = {
            route: 'true',
            }
    }
    onClick() {
        // window.location.href='https://yamheru.wispform.com/32f79abf';
        window.open('https://yamheru.wispform.com/32f79abf?flores=xxxx', '_blank');
    }


render (){

const gretting1 = 'HAZ TU PEDIDO YA!';
const estiloButton1 = 'br3-ns br3 ba color-boton1 ttu black center pt2 pb2 pr4 pl4 f5 f3-ns no-underline grow';


return (
<div className='tc-l vh-100 flex flex-column center justify-center bg-cta'>

    <div className='tc flex justify-center luz pt2 pb4  w-70-l center-l br4-l'>
        <div className=' mt0   pa4-ns  tc flex flex-column'>
            <h1 className='f2 f1-m f-6-l fw1 ttu black-90 mb0 pa0'>Encuentra alegria <br/> y tranquilidad <br/> en tus dias.</h1>
            <h2 className='fw1 f3-ns f5 fw2 black-80 mt2 mb2-ns'>Regala Flores</h2>
            <div className='flex center justify-center mt3'>
                <div  className='mh2' >
                    <Button text = {gretting1}  estilo = {estiloButton1} onClick = {this.onClick} />
                </div>   
            </div>
        </div>
    </div>


    <div className='flex justify-center center  fixed-itresse-bottom luz dn-l '>

        <a className=" contact-details dib ph3 ma2" href="https://instagram.com/yamheru" target="_blank" rel='noreferrer'>
            <i className="fab fa-instagram f2 f1-m f1-l"></i>
        </a>
        <a className=" contact-details dib ph3 ma2" href="https://facebook.com/yamheru" target="_blank" rel='noreferrer'>
            <i className="fab fa-facebook-square f2 f1-m f1-l"></i>
        </a>
        <a className=" contact-details dib ph3 ma2" href="https://wa.me/51948018337?text=Hola%2C+Yamheru%2C+Estoy+interesado+en+sus+diseños+florales" target="_blank" rel='noreferrer'>
            <i className="fab fa-whatsapp f2 f1-m f1-l"></i>
        </a>

    </div>
</div>
    );
}
}


export default CallAction;
