import React, { Component } from 'react';

import Button from '../Button/Button';
import './Cuerpo.css';

class Cuerpo extends Component {
    constructor(props){
        super(props);
        this.state = {
        route: ' ',
        subsInEmail: ''
        }
    }


    onClick() {
        // window.location.href='https://yamheru.wispform.com/32f79abf';
        window.open('https://yamheru.wispform.com/97b9a515?suscripcion=xxxx', '_blank');
    }


    render (){
    
        const gretting = 'SUSCRIBETE YA!';
        const estiloButton = 'br3-ns br3 ba color-boton1 ttu black center pt2 pb2 pr4 pl4 f5 f3-ns no-underline grow';
        
    
    return (
        <div className='img-back-body1  pv6' >
            <article id=' ' className="mw7 center ph3 ph5-ns tc br4-ns pv5 luz ">
                    <h1 className="fw6 f3 f2-ns lh-title mt0 mb3">
                        ¿Que nos generan las flores?
                    </h1>
                    <h2 className="fw2 f4 lh-copy mt0 mb3">
                    Las flores nos brindan y generan emociones cuando son entregadas a nuestros seres queridos.
                    </h2>
                    <div>
                    <Button text = {gretting}  estilo = {estiloButton} onClick = {this.onClick} />
                    </div>
                </article>
            


        </div>
 

    
    );

}
}

export default Cuerpo;
