import React from 'react';


const  Logo = (props) => {
return (
    <div className='tl'>
        <img 
        className={props.estiloImg} 
        src={props.imgSource}
        alt={props.Description}
        />
    </div>
);

}


export default Logo;