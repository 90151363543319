import React, { Component } from 'react';


import Card from './Card/Card';
import imgBlog1 from './proceso1.jpg'
import imgBlog2 from './proceso2.jpg'
import imgBlog3 from './proceso3.jpeg'

class Proceso extends Component {
    render (){
    
    const diseño = 'Diseño';
    const entrega = 'Entrega';
    const disfruta = 'Disfruta';

    const uno = '1';
    const dos = '2';
    const tres = '3';
    
    const disCont = 'Diseñamos tu arreglo floral en base a tus preferencias y estilo.';
    const entCont = 'Definimos el dia, lugar de entrega, formas de pago y frecuencia.';
    const disfCont = 'Disfrutaras de tus flores y te ayudaremos a mantenerlas.';
    
    const estilo = 'center bg-white br4  mv3 mh3 ba b--black-10 w-30-l w-100 ';

    return (
        <div className='img-back-body1 pv4' >
            <div className='tc'>
                <p className='fw6 f3 f2-ns lh-title pt2 ma3'>¿Como Funciona?</p>
                <a href="#acuerdo" class="f5 no-underline bg-animate  hover-pink:pink inline-flex items-center grow">
                <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.32488 2L17 16.9119L29.6751 2H4.32488Z" fill="black" stroke="black" stroke-width="4"/>
                </svg>
                </a>
            </div>

            <div id='proceso' className='flex justify-center-ns flex-wrap w-75 center mv5 '>
                <Card text = {diseño} number = {uno} content = {disCont} estilo = {estilo} imgBlog = {imgBlog1}/> 
                <Card text = {entrega}  number = {dos} content = {entCont} estilo = {estilo} imgBlog = {imgBlog2}/>
                <Card text = {disfruta}  number = {tres} content = {disfCont} estilo = {estilo} imgBlog = {imgBlog3}/>
            </div>
        </div>


        
    );
    
    }
    }
    
    export default Proceso;
    