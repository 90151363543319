import React, { Component } from 'react';

import Logo from '../Logo/Logo';

import yamheru from './yamherufloral.png'
import './Nav.css';


class Nav extends Component {
    constructor(props){
        super(props);
        this.state = {
            route: ''
        }
    }

    onRouteChange = (route) => {
        this.setState({route: route});
        console.dir(this.state.route);
    }

    

    render (){

        const estiloImg2 = 'w-50-ns w-40 grow'

    return (
        <div className='flex items-center justify-between bg-transparent pr3 pl3 pt3 pb3 br3-l fixed-itresse mw-100'>
            <Logo imgSource = {yamheru} estiloImg = {estiloImg2} />
{/*
                { this.state.route === 'hb0'
                    ?<ButtonHB1 onRouteChange={this.onRouteChange}/>
                    :<ButtonHB0 onRouteChange={this.onRouteChange}/>
                }
*/}

        </div>
        
    );

}
}

export default Nav;

