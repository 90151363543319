import React, { Component } from 'react';

import Button from '../Button/Button';
import './Servicios.css';

class Servicios extends Component {
    constructor(props){
        super(props);
        this.state = {
        route: ' ',
        subsInEmail: ''
        }
    }

    
    onClick() {
        // window.location.href='https://yamheru.wispform.com/32f79abf';
        window.open('https://yamheru.wispform.com/32f79abf?flores=xxxx', '_blank');
    }

    onClick_Subs() {
        // window.location.href='https://yamheru.wispform.com/32f79abf';
        window.open('https://yamheru.wispform.com/97b9a515?suscripcion=xxxx', '_blank');
    }

    onClick_Arte() {
        // window.location.href='https://yamheru.wispform.com/32f79abf';
        window.open('https://yamheru.wispform.com/67a2f5bc?arte=xxxx', '_blank');
    }
    
    render (){

        const grettingFlores = 'HAZ TU PEDIDO YA!';
        const grettingSuscripcion = 'SUSCRIBETE YA!';
        const grettingArte = 'RESERVA TU DISEÑO!';
        const estiloButton = 'br3-ns br3 ba color-boton1 ttu black center pt2 pb2 pr4 pl4 f5 f3-ns no-underline grow';
        
    
    return (
        <div id='#nosotros' className='img-back-body1 pv6' >
            <div className='tc'>
                <p className='fw6 f3 f2-ns lh-title pt2 ma3'>Nosotros</p>
                <a href="#acuerdo" class="f5 no-underline bg-animate  hover-pink:pink inline-flex items-center grow">
                <svg width="34" height="20" viewBox="0 0 34 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.32488 2L17 16.9119L29.6751 2H4.32488Z" fill="black" stroke="black" stroke-width="4"/>
                </svg>
                </a>
            </div>

                <div className='flex items-center content-between  br3 w-75-l grow vh-20-l pv3  center  luz mv2 ph3' >

                    <div className='ph3 w-50 '>
                        <h1 className="fw6 f3 f2-ns lh-title pt2 ma3">
                            Flores
                        </h1>
                        <h2 className="fw2 f6 f5-ns lh-copy-ns mt0 mb3 ">
                        Tenemos varios modelos que lo podras revisar en nuestro instagram <a href='https://instagram.com/yamheru' className='red underline'>@yamheru</a> o revisar nuestro <a href='yonnkeenn.com' className='red underline'>catalogo</a> de ramos, sombrereras, centros de mesa, etc.
                        </h2>
                    </div>
                    
                    <div className='w-50 ma0 pa0 dn-s' >
                        <div className='tc'>
                        <Button text = {grettingFlores}  estilo = {estiloButton} onClick = {this.onClick} />
                        </div>
                        
                    </div>
                </div>

                <div className='flex items-center content-between  br3 w-75-l grow vh-20-l pv3  center  luz mv2 ph3' >
                    
                    <div className='w-50 ma0 pa0 dn-s' >
                        <div className='tc'>
                        <Button text = {grettingSuscripcion}  estilo = {estiloButton} onClick = {this.onClick_Subs}  />
                        </div>
                    </div>

                    <div className='ph3 w-50'>
                        <h1 className="fw6 f3 f2-ns lh-title pt2 ma3">
                            Suscripcion
                        </h1>
                        <h2 className="fw2 f6 f5-ns lh-copy-ns mt0 mb3 ">
                        Si te gustan la flores y tener espcios llenos de vida en tu hogar, oficina o lugares favoritos, recibe flores semanales, quincenales o en el plan que deseas.
                        </h2>
                    </div>
                    
                </div>

                <div className='flex items-center content-between  br3 w-75-l grow vh-20-l pv3  center  luz mv2 ph3' >
                    
                    <div className='ph3 w-50"'>
                        <h1 className="fw6 f3 f2-ns lh-title pt2 ma3">
                            Arte
                        </h1>
                        <h2 className="fw2 f6 f5-ns lh-copy-ns mt0 mb3 ">
                        Basados en tecnicas de prensado botatico, elaboramos hermosos cuadros personalizados que puedes ser regalados en ocasiones especiales.
                        </h2>
                    </div>
                    
                    <div className='w-50 ma0 pa0 dn-s' >
                        <div className='tc'>
                        <Button text = {grettingArte}  estilo = {estiloButton} onClick = {this.onClick_Arte}  />
                        </div>
                    </div>

                </div>         


        </div>

    
    );

}
}

export default Servicios;
