import React, { Component } from 'react';
import Nav from './components/Nav/Nav'
import CallAction from './components/CallAction/CallAction.js';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar';
import Proceso from './components/Proceso/Proceso';
import BodyCard from './components/BodyCard/BodyCard';
import Cuerpo from './components/Cuerpo/Cuerpo';
import Servicios from './components/Servicios/Servicios';
import Problemas from './components/Problemas/Problemas';

class App  extends Component {
  constructor(props){
    super(props);
    this.state = {
      route: ' '
    
    }
  }

  onSubsChange = (event) => {
    this.setState({subsInEmail: event.target.value})
    console.log(this.subsInEmail);
}

  render (){
    return (
      <div className='App'>
        <Nav />
        <Sidebar onRouteChange={this.onRouteChange} />
        <CallAction />
        <Proceso />
        <Problemas />
        <Servicios />
        <BodyCard />
        <Cuerpo />
        <Footer />
      </div>
  );

  }
}


export default App;